import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "./video-modal.css"

export default class Vmodal extends Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    return (
      <>
        <button
          className="video-modal-thumbnail"
          onClick={this.handleShow}
          style={{ cursor: "pointer" }}
        >
          <LazyLoadImage
            effect="blur"
            src={this.props.thumbnail}
            alt=""
            className="img-fluid"
          />
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          centered="true"
          className="video-modal-wrapper"
        >
          <button
            className="close-video-modal-btn"
            title="Close"
            onClick={this.handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.001 512.001"
              width="14"
              height="14"
              fill="#fff"
            >
              <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z" />
            </svg>
          </button>
          <div
            className={`embed-container ${
              this.props.aspectRatio && this.props.aspectRatio === "letterbox"
                ? "letterbox"
                : "widescreen"
            } `}
          >
            <iframe
              src={this.props.src}
              frameborder="0"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
              title="Video"
            ></iframe>
          </div>
        </Modal>
      </>
    )
  }
}
